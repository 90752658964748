import { mapGetters } from 'vuex';
import download from '@/mixins/plugin/download';

export default {
  data: function() {
    return {
      isImportDisabled: false,
      timerId: null,
    }
  },
  mixins: [download],
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
    }),
    importPath() {
      return `${this.userInfo?.id}_${this.subdomain}_${this.importStatusOptions.type}`;
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
  },
  methods: {
    checkImportStatus(id) {
      if (!this.isImportDisabled) this.setImportingCsv();
      const { type, resetCheckbox, isDownloadCsv, action, isCountMessage } = this.importStatusOptions;
      const commonAction = 'common/getImportStatus';
      const customId = this.importStatusOptions?.canUseParams ? {
        id: id,
        eventId: this.importStatusOptions?.canUseParams?.id
      } : id;
      this.statusTimer = setTimeout(async () => {
        const result = await this.$store.dispatch(action || commonAction, customId);
        if (result) {
          switch (result.status) {
            case 0:
              this.checkImportStatus(id);
              break;
            case 1:
              if (this.customSuccessCallback) {
                this.customSuccessCallback(result);
              } else {
                !isCountMessage ? this.$message.showSuccess(`import_${type}_finished_success`) :
                  this.$message.showSuccess(`import_${type}_finished_success`, false, 'count', result?.count);
              }
              if (this.$refs.pagination) this.$refs.pagination.resetPagination();
              if (resetCheckbox) this.resetCheckbox();
              this.removeImportingCsv();
              break;
            case 2:
              if (result.count && this.$refs.pagination) this.$refs.pagination.resetPagination();
              if (isDownloadCsv) this.downloadCsv(result.errorData, result.filename);
              if (resetCheckbox) this.resetCheckbox();
              !isCountMessage ? this.$message.showSuccess(`import_${type}_finished_fail`) :
                this.$message.showSuccess(`import_${type}_finished_fail`, false, 'count', result?.count);
              this.removeImportingCsv();
              break;
            case 3:
              this.$message.showError(`import_${type}_fail`);
              if (this.$refs.pagination) this.$refs.pagination.resetPagination();
              if (isDownloadCsv && !!result.errorData) this.downloadCsv(result.errorData, result.filename);
              if (resetCheckbox) this.resetCheckbox();
              this.removeImportingCsv();
              break;
            case 4:
              this.$message.showError(`import_${type}_duplicate_fail`);
              if (this.$refs.pagination) this.$refs.pagination.resetPagination();
              this.removeImportingCsv();
              if (resetCheckbox) this.resetCheckbox();
              break;
            default:
              break;
          }
        } else {
          this.removeImportingCsv();
          if (resetCheckbox) this.resetCheckbox();
        }
      }, 10000);
    },
    setImportingCsv(id) {
      this.isImportDisabled = true;
      const importingAttendStore = JSON.parse(localStorage.getItem(this.importingItem)) || {};
      importingAttendStore[this.importPath] = id;
      localStorage.setItem(this.importingItem, JSON.stringify(importingAttendStore));
    },
    removeImportingCsv() {
      this.isImportDisabled = false;
      const importingAttendStore = JSON.parse(localStorage.getItem(this.importingItem)) || {};
      delete importingAttendStore[this.importPath];
      localStorage.setItem(this.importingItem, JSON.stringify(importingAttendStore));
    },
    async checkCanUseImport() {
      let result = await this.$store.dispatch(this.importStatusOptions.canUseAction, {
        subdomain: this.subdomain,
        ...(this.importStatusOptions?.canUseParams || {})
      });
      if (!result) {
        this.isImportDisabled = true;
      } else {
        clearInterval(this.timerId);
        this.isImportDisabled = false;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timerId);
    clearTimeout(this.statusTimer)
  },
  async mounted() {
    const importingId = (JSON.parse(localStorage.getItem(this.importingItem)) || {})[this.importPath];
    if (importingId) {
      this.isImportDisabled = true;
      this.checkImportStatus(importingId);
    } else {
      const loading = this.$loading.show();
      if (this.importStatusOptions?.canUseParams) {
        await this.$store.dispatch('event/getChildEventDetail');
      } else {
        this.initForm();
      }
      Promise.all([
        this.checkCanUseImport()
      ]).finally(() => {
        this.timerId = setInterval(() => this.checkCanUseImport(), 5000);
        this.$loading.clear(loading);
      })
    }
  }
};
